<template>
    <div>
        <div class="max-w-xl mx-auto px-4 md:px-6">
            <div class="pt-10">
                <div class="flex justify-center">
                    <div class="w-32 h-32">
                        <img v-if="user?.profile_image" :src="user?.profile_image" alt="profile image" class="shadow rounded-full w-32 h-32 border-none" />
                        <div v-else class="border-2 border-gray-400 rounded-full max-w-full h-auto">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path d="M248,8C111,8,0,119,0,256S111,504,248,504,496,393,496,256,385,8,248,8Zm0,96a88,88,0,1,1-88,88A88,88,0,0,1,248,104Zm0,344a191.61,191.61,0,0,1-146.5-68.2C120.3,344.4,157.1,320,200,320a24.76,24.76,0,0,1,7.1,1.1,124.67,124.67,0,0,0,81.8,0A24.76,24.76,0,0,1,296,320c42.9,0,79.7,24.4,98.5,59.8A191.61,191.61,0,0,1,248,448Z" class="fill-color-second"/><path d="M248,280a88,88,0,1,0-88-88A88,88,0,0,0,248,280Zm48,40a24.76,24.76,0,0,0-7.1,1.1,124.67,124.67,0,0,1-81.8,0A24.76,24.76,0,0,0,200,320c-42.9,0-79.7,24.4-98.5,59.8,68.07,80.91,188.84,91.32,269.75,23.25A192,192,0,0,0,394.5,379.8C375.7,344.4,338.9,320,296,320Z" class="fill-color-main"/></svg>
                        </div>
                    </div>
                </div>
                <div class="text-white text-center pt-4 font-bold">
                    <h2 class="text-xl font-semibold text-white">{{ user?.name }}</h2>
                </div>
            </div>
            <div class="py-6 relative flex justify-around w-full border-b">
                <div class="flex-1 justify-center my-auto text-center">
                    <span class="block text-scorelitorange font-bold text-lg">{{ user?.video_count ?? '-' }}</span>
                    <span class="block text-gray-400 text-sm">{{ t('videos') }}</span>    
                </div>

                <div class="flex-1 justify-center my-auto text-center">
                    <span class="block text-scorelitorange font-bold text-lg">{{ user?.report_count ?? '-' }}</span>
                    <span class="block text-gray-400 text-sm">{{ t('reviews') }}</span>    
                </div>

                <div class="flex-1 justify-center my-auto text-center">
                    <span class="block text-scorelitorange font-bold text-lg">{{ user?.average_rating ?? '-' }}</span>
                    <span class="block text-gray-400 text-sm">{{ t('rating') }}</span>    
                </div>
            </div>
            <div class="mt-6">
                <router-link :to="{name: 'reviews'}">
                    <span class="block w-full rounded-md shadow-sm">
                        <button type="button " class="button w-full">
                            {{ t('show_reviews') }}
                        </button>
                    </span>
                </router-link>
            </div>

            <span class="block w-full rounded-md shadow-sm">
                <button v-for="(info_item, index) in info" :key="index" class="p-4 rounded font-medium text-sm transition-all duration-200 ease-in bg-scorelitgray w-full mt-4 px-6">
                    <span class="text-gray-400 text-xs mr-4 float-left pl-3 py-1">
                        {{ info_item.key }}
                    </span>
                    <span class="float-right text-white font-medium text-xs truncate ... pr-3 py-1">
                        {{ info_item.val }}
                    </span>
                </button>
            </span>

            <div class="mt-8">
                <router-link :to="{name: 'settings'}">
                    <span class="block w-full rounded-md shadow-sm">
                        <button type="button" class="button-transparent w-full">
                            {{ t('settings') }}
                        </button>
                    </span>
                </router-link>
                <span @click="logout" class="block w-full rounded-md shadow-sm mt-5">
                    <button type="button" class="button-transparent w-full">
                        {{ t('logout') }}
                    </button>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import messages from "@/helpers/storage/MessageHelper";
import { logout } from "@/helpers/api/AuthHelper";
import { indexSport } from "@/helpers/api/SportHelper";
import useI18n from "@/modules/globalI18n";

export default {
    name: "ProfileShow",
    setup() {
        return useI18n({
            viewPrefix: "ProfileShow"
        });
    },
    created() {
        indexSport()
            .then ((sports) => {
                const user_sport = sports.find(sport => sport.id === this.user?.sport_id);
                this.user_sport_name = user_sport.name;
            })
    },
    data () {
        return  {
            user_sport_name: "",
        };
    },
    computed: {
        user: function(){
            return this.$store.getters['getUser'];
        },
        info: function(){
            if (this.user){
                return [
                    {
                        key: this.t('info_keys.sport'),
                        val: this.user_sport_name,
                    },
                    {
                        key: this.t('info_keys.current_club'),
                        val: this.user?.club,
                    },
                    {
                        key: this.t('info_keys.favorite_position'),
                        val: this.user?.position,
                    },
                    {
                        key: this.t('info_keys.email'),
                        val: this.user?.email,
                    },
                ];
            }
            return [];
        },
    },
    methods: {
        logout(){
            logout()
                .then(() => {
                    this.$router.push("/login");
                })
                .catch(err => {
                    messages.error(this.t('messages.unsuccessful_logout') + err.message);
                    console.error('Logout unsuccessful: ', err.message)
                });
        },
    }
}
</script>

<style scoped>
.fill-color-main {
    fill: #757575;
}
</style>